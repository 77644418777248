import { SEO, SignupCopy, WizardLayout } from 'components';
import React from 'react';
import { useTracking } from '../utilities/hooks';

const SignupPage = () => {
  useTracking('Signup');

  return (
    <WizardLayout>
      <SEO title="Signup" />
      <SignupCopy />
    </WizardLayout>
  );
};

export default SignupPage;
